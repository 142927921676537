import axios from "../../../services/Api";

export default {
  methods: {
    async waitForAuthData() {
      console.log("entered method");
      window.addEventListener('message', async function(event) {
        console.log("event listener fired");
        if (event?.data?.type === 'omniwallet_auth') {
          console.log("entered if statement");
          this.loading = true;
          await axios.get("/sanctum/csrf-cookie");
          await axios
            .post("front-catalog/token-login", {
              key: event.data.data
            })
            .then(() => {
              console.log("entered then");
              this.signIn();
              console.log("sign in done");
              this.loading = false;
            })
            .catch((error) => {
              console.log("error login: ", error);
              this.wrongCredentials = true;
              this.loading = false;
            });
        } 
      }.bind(this));
    },
  },
};
